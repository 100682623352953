@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto Condensed", sans-serif;

}
.main {
  width: 100%;
  height:100vh;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:rgba(0, 0, 0, .4) ;

}
.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

}
.text {
  /* background-color: black;
  border: 1px solid white; */
  padding: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 50px;
  }
  h2 {
    padding-top: 50px;
    font-size: 25px;
    font-weight: 300;
  }

  h3 {
    padding-top: 50px;
    font-weight: 100;

  }

}

form {
  input {
    padding: 10px;
    width: 278px;
    border: 1px solid white;
  }
  button {
    border: 1px solid white;
    padding: 10px;
    width: 300px;
    color: white;
    background-color: transparent;
    cursor: pointer;


  }
}

@media only screen and (max-width: 600px) {
  h1{
    font-size: 25px !important;
  }
  h2 {
    padding: 5px 0 !important;
    font-weight: 100;
    font-size: 18px !important;
  }

  h3 {
    padding-top: 5px;
    font-size: 20px;

  }
  h4 {
    padding-top: 5px;
    font-weight: 100;
    font-size:12px;

  }
  .join {
    padding: 5px !important;
  }
  .logo {
    height: 120px;
  }
  form {
    input {
      padding: 5px;
      width: 178px;
      border: 1px solid white;
    }
    button {
      border: 1px solid white;
      padding: 5px;
      width: 100px;
      color: white;
      background-color: transparent;
      cursor: pointer;
  
  
    }
  }
}
